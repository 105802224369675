<template>
  <v-card>
    <v-dialog v-model="openConfirm" width="300" persistent>
      <v-card>
        <v-card-title> Confirmation </v-card-title>
        <v-card-subtitle> {{ confirmTitle }} </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="openConfirm = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="applyAction">
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" v-if="!!savedAt || !!savedBy">
            <v-alert border="right" colored-border type="info" elevation="2">
              <b>Saved At:</b> {{ styleDate(savedAt) }}
              <b class="ml-3">Saved By:</b>
              {{ savedBy }}
            </v-alert>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-autocomplete
              label="COMPETITION"
              :items="competitions"
              :rules="rule"
              v-model="competition"
              :open-on-clear="false"
              item-value="acronym"
              item-text="name"
              :loading="loader"
              :disabled="loader"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              label="YEAR"
              :items="years"
              :rules="rule"
              v-model="year"
              :open-on-clear="false"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn color="primary" block @click="getData" :disabled="loading">
              Submit
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-btn
              color="primary"
              block
              @click="getCalculation"
              :disabled="loading"
            >
              Recalculate
            </v-btn>
          </v-col>
          <v-col cols="12" :sm="approvedBy.length > 1 ? 4 : 6" md="4">
            <v-btn
              color="primary"
              block
              @click="confirmApprove"
              :disabled="loading || approved"
              v-if="showApprove"
            >
              {{ approved ? 'Approved' : 'Approve' }}
            </v-btn>
          </v-col>
          <v-col cols="12" :sm="approvedBy.length > 1 ? 4 : 6" md="4">
            <v-btn
              color="primary"
              block
              @click="confirmSave"
              :disabled="loading"
              v-if="showSave"
            >
              Save
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" v-if="approvedBy.length > 1">
            <v-btn color="primary" block :disabled="loading">
              Notify Winners
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-title>
    <v-banner v-if="approvedBy.length > 0" color="light-blue lighten-5">
      <v-icon slot="icon" color="primary" size="36">
        mdi-check-decagram
      </v-icon>
      {{ bannerMsg }}
    </v-banner>
    <list-view
      ref="listView"
      :title="'Competition Calculator'"
      :headers="headers"
      :records="items"
      :async-loading="loading"
      :total-docs="items.length"
      :footer-options="footer"
      group-by="categoryName"
      hideFooter
      exportable
      @export="v => exportFile(v)"
    >
      <template slot="full-name" slot-scope="{ row }">
        <span>{{ getFullName(row) }}</span>
      </template>
      <template slot="competition-place" slot-scope="{ row }">
        <v-chip small class="ma-2" color="primary" v-if="!row.disqualified">
          {{ row.place }}
        </v-chip>
      </template>
      <template slot="qualify" slot-scope="{ row }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn-fix"
              fab
              small
              depressed
              @click="askConfirm(row)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-account-cancel </v-icon>
            </v-btn>
          </template>
          <span v-text="row.disqualified ? 'Un-disqualify' : 'Disqualify'" />
        </v-tooltip>
      </template>
      <template slot="list-item" slot-scope="{ row }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              depressed
              @click="editUser(row)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-account-edit </v-icon>
            </v-btn>
          </template>
          <span>Edit Profile</span>
        </v-tooltip>
      </template>
    </list-view>
    <v-bottom-sheet
      v-model="sheet"
      persistent
      :fullscreen="full"
      :scrollable="full"
    >
      <new-user
        ref="newUser"
        @close="
          sheet = false;
          userId = null;
        "
      />
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import ListView from '../../components/appComponents/ListView.vue';
import {
  formatDate,
  formatPhone,
  formatStr,
  getFileDeprecated,
  notifyMessage
} from '../../helpers/handler';
import NewUser from '../users/forms/sections/NewUser.vue';
export default {
  components: { ListView, NewUser },
  name: 'competition',
  data: () => ({
    headers: Headers,
    items: [],
    originalItems: [],
    footer: {
      pageCount: 0,
      showFirstLastPage: true
    },
    loader: false,
    loading: false,
    totalDocs: 0,
    options: null,
    competitions: [],
    competition: null,
    year: null,
    rule: [v => !!v || 'Required'],
    sheet: false,
    openConfirm: false,
    confirmTitle: '',
    rowSelected: null,
    disqualified: [],
    undisqualified: [],
    approvedBy: [],
    approved: false,
    savedAt: null,
    savedBy: null,
    email: null,
    continue: () => {},
    userId: null
  }),
  mounted() {
    this.getCompetitions();
    this.email = JSON.parse(localStorage.getItem('data'))?.user?.email;
  },
  beforeRouteLeave(to, from, next) {
    if (this.disqualified.length > 0 || this.undisqualified.length > 0) {
      this.confirmTitle =
        'You have unsaved changes. Do you want to go out anyway?';
      this.continue = next;
      this.openConfirm = true;
    } else next();
  },
  computed: {
    years() {
      const years = [];
      for (let year = 2012; year <= moment().year(); year++) {
        years.push(year);
      }
      return years.reverse();
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    bannerMsg() {
      let msg = 'This competition is approved by ';
      this.approvedBy.forEach((email, index) => {
        msg = index === 0 ? msg.concat(email) : msg.concat(` and ${email}`);
      });
      this.approvedBy.length > 1 &&
        (msg = msg.concat('. The winners can be notified.'));
      return msg;
    },
    showSave() {
      const empty = Object.keys(this.originalItems).length === 0;
      const saved = !!this.savedBy || !!this.savedAt;
      const edited =
        this.disqualified.length > 0 || this.undisqualified.length > 0;
      return (!saved || edited) && !empty;
    },
    showApprove() {
      const empty = Object.keys(this.originalItems).length === 0;
      const isAuthor =
        !!this.email && !!this.savedBy && this.email === this.savedBy;
      const approved = this.approvedBy.length >= 2;
      return !isAuthor && !approved && !empty;
    }
  },
  watch: {
    sheet: {
      handler: 'initEditUser',
      immediate: true
    }
  },
  methods: {
    ...mapActions('query', [
      'getCompetition',
      'setDisqualify',
      'getSavedCompetition',
      'saveCompetition',
      'setApproval'
    ]),
    initEditUser(value) {
      if (value) {
        setTimeout(async () => {
          if (this.$refs.newUser) {
            await this.$refs.newUser.init(this.userId);
          }
        }, 100);
      }
    },
    askConfirm(row) {
      this.rowSelected = row;
      this.confirmTitle = row?.disqualified
        ? 'Are you sure you would like to un-disqualify this person?'
        : 'Are you sure you would like to disqualify this person?';
      this.openConfirm = true;
    },
    async disqualify() {
      const { user, disqualified, categoryName: category, categoryAcronym } =
        this.rowSelected || {};

      let categoryName = category;
      const hasSubcategory = categoryName && String(categoryName).includes('(');
      hasSubcategory &&
        (categoryName = String(categoryName)
          .split('(')[0]
          .trimEnd());

      Object.keys(this.originalItems).forEach(category => {
        if (this.getCategoryName(category) === categoryName) {
          if (this.originalItems[category] instanceof Array) {
            this.originalItems[category].forEach(item => {
              if (item?.user?._id === user?._id) {
                item.disqualified = !disqualified;
              }
            });
          } else {
            Object.keys(this.originalItems[category]).forEach(specie => {
              this.originalItems[category][specie].forEach(item => {
                if (item?.user?._id === user?._id) {
                  item.disqualified = !disqualified;
                }
              });
            });
          }
        }
      });

      if (!disqualified) {
        const index = this.undisqualified.findIndex(id => id === user?._id);
        if (index === -1) {
          !this.disqualified.some(item => item.id === user?._id) &&
            this.disqualified.push({
              id: user?._id,
              category: categoryAcronym
            });
        } else this.undisqualified.splice(index, 1);
      } else {
        const index = this.disqualified.findIndex(
          item => item.id === user?._id
        );
        if (index === -1) {
          !this.undisqualified.some(item => item.id === user?._id) &&
            this.undisqualified.push({
              id: user?._id,
              category: categoryAcronym
            });
        } else this.disqualified.splice(index, 1);
      }

      this.rebuildList(this.originalItems);
      this.rowSelected = null;
    },
    async getCompetitions() {
      this.loader = true;
      const response = await this.getCompetition();
      this.competitions = response?.data?.docs || [];
      this.loader = false;
    },
    async getData() {
      const valid = this.$refs.form && this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        const response = await this.getSavedCompetition({
          year: this.year,
          acronym: this.competition
        });

        const { categories, approvedBy, updatedAt, updatedBy } =
          response?.data?.docs || {};
        const docs = categories || [];
        this.approvedBy = approvedBy || [];
        this.savedAt = updatedAt;
        this.savedBy = updatedBy;
        this.disqualified = [];
        this.undisqualified = [];
        this.approved = this.approvedBy.some(elem => elem === this.email);
        if (docs.length === 0) {
          this.getCalculation();
        } else this.rebuildList(docs);
        this.loading = false;
      }
    },
    async getCalculation() {
      const valid = this.$refs.form && this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        const response = await this.getCompetition({
          year: this.year,
          acronym: this.competition
        });

        this.rebuildList(response?.data?.docs || []);
        this.approvedBy = [];
        this.savedAt = null;
        this.savedBy = null;
        this.approved = false;
        this.disqualified = [];
        this.undisqualified = [];
        this.loading = false;
      }
    },
    applyAction() {
      this.openConfirm = false;
      String(this.confirmTitle).includes('approve')
        ? this.approve()
        : String(this.confirmTitle).includes('modify')
        ? this.saveCalculation()
        : String(this.confirmTitle).includes('unsaved')
        ? this.continue()
        : this.disqualify();
    },
    rebuildList(docs) {
      this.items = [];
      this.originalItems = docs;
      Object.keys(docs).forEach(doc => {
        if (docs[doc] instanceof Array) {
          let place = 0;
          let checkSpecie = '';
          docs[doc].forEach(item => {
            if (item.specie !== checkSpecie) {
              place = 0;
              checkSpecie = item.specie;
            }
            !item?.disqualified && (place = place + 1);
            this.items.push({
              ...item,
              category: this.getCategoryName(doc),
              categoryName: this.getCategoryName(doc),
              place: item?.disqualified ? null : this.getPlaces(place)
            });
          });
        } else {
          Object.keys(docs[doc]).forEach(specie => {
            let place = 0;
            let checkSpecie = '';
            docs[doc][specie].forEach(item => {
              if (item.specie !== checkSpecie) {
                place = 0;
                checkSpecie = item.specie;
              }
              !item?.disqualified && (place = place + 1);
              this.items.push({
                ...item,
                category: this.getCategoryName(doc) + ' (' + specie + ')',
                categoryName: this.getCategoryName(doc) + ' (' + specie + ')',
                place: item?.disqualified ? null : this.getPlaces(place)
              });
            });
          });
        }
      });
    },
    async approve() {
      this.loading = true;
      const response = await this.setApproval({
        year: this.year,
        acronym: this.competition,
        email: this.email
      });
      response?.data?.success && (this.approved = true);
      this.loading = false;
    },
    async saveCalculation() {
      const valid = this.$refs.form && this.$refs.form.validate();
      if (valid && Object.keys(this.originalItems).length > 0) {
        this.loading = true;
        const deadline = moment(`11-15-${moment().year()}`, 'MM-DD-YYYY');
        const year = moment().isAfter(deadline) ? this.year + 1 : this.year;
        let responseDisqualify, responseUndisqualify;

        this.disqualified.length &&
          (responseDisqualify = await this.setDisqualify({
            email: this.email,
            enable: true,
            body: this.disqualified.map(item => ({
              userId: item.id,
              year,
              category: item.category
            }))
          }));

        this.undisqualified.length &&
          (responseUndisqualify = await this.setDisqualify({
            email: this.email,
            enable: false,
            body: this.undisqualified.map(item => ({
              userId: item.id,
              year,
              category: item.category
            }))
          }));

        if (
          (!this.disqualified.length || responseDisqualify?.data?.success) &&
          (!this.undisqualified.length || responseUndisqualify?.data?.success)
        ) {
          const response = await this.saveCompetition({
            year: this.year,
            acronym: this.competition,
            email: this.email,
            body: this.originalItems
          });

          const { success, docs } = response?.data || {};

          if (success) {
            this.approve = false;
            this.disqualified = [];
            this.undisqualified = [];
            notifyMessage(docs?.message);
          } else notifyMessage('Something went wrong');
        } else notifyMessage('Something went wrong, try again');

        this.loading = false;
      }
    },
    confirmApprove() {
      this.confirmTitle = 'Are you sure to approve this data?';
      this.openConfirm = true;
    },
    confirmSave() {
      this.confirmTitle = `This action could modify competition's results. Do you want to proceed?`;
      this.openConfirm = true;
    },
    getPlaces(place) {
      return place > 3
        ? `${place}th`
        : place === 3
        ? '3rd'
        : place === 2
        ? '2nd'
        : '1st';
    },
    getCategoryName(name) {
      return String(name)
        .split('_')
        .join(' ');
    },
    editUser(item) {
      const { id, _id } = item?.user || {};
      this.userId = id || _id;
      this.sheet = true;
    },
    styleDate(d) {
      return formatDate(d);
    },
    exportFile(fileType) {
      this.overlay = true;
      let species = [];
      this.items?.forEach(item => {
        const list = item?.species?.map(specie => specie.name) || [];
        species = [...species, ...list];
      });
      species = species?.filter(
        (item, index, array) => !!item && array.indexOf(item) === index
      );
      const result = this.items?.map(record => {
        const user = record?.user;
        let row = {
          CATEGORY: formatStr(record?.categoryName),
          PLACE: formatStr(record?.place),
          ANGLER: formatStr(`${user?.firstName} ${user?.lastName}`),
          SPECIE: record?.specie,
          TOTALFISH: record?.capturesTotal
        };

        species?.forEach((specie, index) => {
          const value =
            record?.species?.find(item => item.name === specie)?.qty || null;
          row = {
            ...row,
            [`FISH_${index + 1}`]: value,
            [`SPECIES_${index + 1}`]: value ? specie : null
          };
        });

        row = {
          ...row,
          ADDRESS: formatStr(user?.address?.line1),
          CITY: formatStr(user?.address?.city),
          STATE: formatStr(user?.address?.state),
          COUNTRY: formatStr(user?.address?.country),
          ZIP: formatStr(user?.address?.zip),
          PHONE: formatPhone(user?.homePhone),
          EMAIL: formatStr(user?.email)
        };

        return row;
      });

      let colsWidth = [
        { wch: 55 },
        { wch: 6 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 }
      ];

      species?.forEach(() => {
        colsWidth = [...colsWidth, { wch: 6 }, { wch: 15 }];
      });

      colsWidth = [
        ...colsWidth,
        { wch: 25 },
        { wch: 25 },
        { wch: 10 },
        { wch: 12 },
        { wch: 10 },
        { wch: 15 },
        { wch: 30 }
      ];

      getFileDeprecated(result, colsWidth, fileType, 'Competition Calculator');

      this.overlay = false;
    },
    getFullName(row) {
      const { firstName, lastName } = row?.user || {};
      return firstName && lastName ? `${firstName} ${lastName}` : null;
    }
  }
};

const Headers = [
  {
    text: '',
    align: 'center',
    sortable: false,
    value: 'categoryName'
  },
  // {
  //   text: 'CATEGORY',
  //   align: 'center',
  //   sortable: false,
  //   value: 'category',
  //   width: '300px'
  // },
  {
    text: '',
    align: 'center',
    sortable: false,
    value: 'place'
  },
  {
    text: 'ANGLER',
    align: 'start',
    sortable: false,
    value: 'fullName'
  },
  {
    text: 'SPECIES',
    align: 'center',
    sortable: false,
    value: 'specie'
  },
  {
    text: 'TOTALFISH',
    align: 'start',
    sortable: false,
    value: 'capturesTotal'
  },
  {
    text: 'BOAT',
    align: 'start',
    sortable: false,
    value: 'boat'
  },
  { text: '', value: 'qualify', sortable: false },
  {
    text: 'ADDRESS',
    align: 'start',
    sortable: false,
    value: 'user.address.line1'
  },
  {
    text: 'CITY',
    align: 'start',
    sortable: false,
    value: 'user.address.city'
  },
  {
    text: 'STATE',
    align: 'start',
    sortable: false,
    value: 'user.address.state'
  },
  {
    text: 'COUNTRY',
    align: 'start',
    sortable: false,
    value: 'user.address.country'
  },
  {
    text: 'ZIP',
    align: 'start',
    sortable: false,
    value: 'user.address.zip'
  },
  {
    text: 'PHONE',
    align: 'start',
    sortable: false,
    value: 'user.homePhone',
    width: '200px'
  },
  {
    text: 'EMAIL',
    align: 'start',
    sortable: false,
    value: 'user.email'
  },
  {
    text: 'EDIT',
    sortable: false,
    value: 'actions'
  }
];
</script>

<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
